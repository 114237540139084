<template>
  <!-- Semis -->
  <div class="fiche-pesees-semis-subview">
    <teleport to="#header-action">
      <Btn
        v-if="helperService.userHasPermission('essaievenement_oedit')"
        class="action-btn"
        icon="create"
        color="white"
        hollow
        text="Modifier"
        :to="{
          name: 'essaiExperimentalTaskFichePeseesSemisEdit',
          params: {
            id: $route.params.id,
            tid: $route.params.tid,
          }
        }"
      />
    </teleport>
    <div class="tabs-material-subtitle no-padding-bottom">
      <Container>
        <h2>Fiche de pesées</h2>
      </Container>
    </div>
    <Section>
      <Container>
        <div class="btn-bar btn-bar--right btn-bar--sub-h2">
          <Btn
            color="primary"
            hollow
            text="Exporter la fiche de pesées"
            @click="download('xls', 'evenement', $route.params.tid)"
          />
        </div>
        <Table
          :headers="headers"
          :static-headers="staticHeaders"
          :items="formattedModalities"
          :hiddenItems="['id', 'ordre']"
        />
      </Container>
    </Section>
  </div>
  <Loader :active="loading"/>
</template>

<script>
import Btn from '@/components/base/Btn.vue'
import Table from '@/components/table/Table.vue'
import Container from '@/components/layout/Container.vue'
import Loader from '@/components/layout/Loader.vue'
import Section from '@/components/layout/Section.vue'

export default {
  name: 'FichePeseesSemisSubview',
  components: {
    Btn,
    Table,
    Container,
    Loader,
    Section,
  },

  props: {
    pageTitle: {
      type: String,
    },
    task: {
      type: Object,
    },
    isEditable: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      loading: false,
      headers: [],
      staticHeaders: [
        'Variété',
        'Densité Semis',
        'PMG',
        'Faculté <br>germinative',
        'Nb rép.',
        'Surface <br>implantation',
        'Quant. calculée <br>par microparcelle',
        'Pesée <br>réelle',
        'Réalisé <br>par',
        'Réalisé <br>le',
        'Observations',
      ],
      modalities: {},
      formattedModalities: [],
    }
  },

  emits: ['set-editable'],

  async mounted() {
    this.loading = true
    this.modalities = await this.getModalites()
    this.formattedModalities = this.formatModalities(this.modalities)
  },
  methods: {
    async getModalites() {
      const endpoint = `essai/${this.$route?.params?.id}/evenement/${this.$route?.params?.tid}/fiche/semis/pesee/modalite`
      const response = await this.fetchService.get(endpoint, { limit: 0 })
      const datas = await response.data
      return datas
    },

    formatModalities(modalities) {
      let datas = []
      modalities.forEach((modality) => {
        const data = {
          id: modality.id,
          ordre: modality.modalite?.ordre,
          variete: modality.produit?.nom,
          densite_semis: this.taskService.concatValueUnit(
            modality.semence ? modality.semence.densite : 'NC',
            modality.semence && modality.semence.unite ? modality.semence.unite.valeur : '',
          ),
          pmg: modality.pmg,
          faculte_germinative: modality.faculte_germinative,
          nb_repetitions: modality.nb_repetitions,
          surface_implantation: this.taskService.concatValueUnit(
            modality.surface_implantation ?? 'NC',
            modality.surface_implantation ? 'm²' : '',
          ),
          quantite_microparcelle: this.taskService.concatValueUnit(
            modality.quantite_microparcelle ? this.helperService.round(modality.quantite_microparcelle, 3) : 'NC',
            modality.quantite_microparcelle ? modality.fiche_semis_pesee?.unite?.valeur : '',
          ),
          pesee_reelle: this.taskService.concatValueUnit(
            modality.pesee_reelle ? this.helperService.round(modality.pesee_reelle, 3) : 'NC',
            modality.pesee_reelle ? modality.fiche_semis_pesee?.unite?.valeur : '',
          ),
          trigramme: modality.trigramme,
          date: modality.date?.split(' ').shift(),
          observations: modality.observations,
        }
        datas.push(data)
      })
      datas = this.helperService.sortByOrder(datas)
      this.loading = false
      return datas
    },

    download(type, ressource, rid) {
      this.loading = true
      const endpoint = `taches/type/${type}/ressource/${ressource}/${rid}/fiche-pesee`
      const filename = `evenement_${rid}_fiche_pesee_semis`
      this.fileService.downloadFile(endpoint, filename, type)
      this.loading = false
    },
  },
}
</script>
